// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

/* eslint-disable react/no-deprecated */

import { createElement, Component } from 'react';
import PropTypes from 'prop-types';
import { prepareUrl, api } from 'jsw';
import { Redirect, withRouter } from 'react-router-dom';
import DataContext from './DataContext';
import statusMessages from '../../helpers/statusMessages';

class RequireComponent extends Component {
    state = {
        Component: null,
    };

    componentDidMount() {
        // Cleanup HTML page content when render React page
        if (document.getElementById('main')) {
            document.getElementById('main').innerHTML = '';
        }
        if (document.querySelector('.b-content-side > div')) {
            document.querySelector('.b-content-side > div').innerHTML = '';
        }

        const { location, component } = this.props;
        const dataPath = this.getDataPath(location);

        this.load(component, dataPath);
    }

    componentWillReceiveProps({ location, component }) {
        const dataPath = this.getDataPath(location);

        if (this.getDataPath(this.props.location) !== dataPath) {
            this.load(component, dataPath);
        }
    }

    getDataPath(location) {
        const { pathname, search } = location;

        let searchParams = new URLSearchParams(search);
        for (const key of [...searchParams.keys()]) {
            if (key.match(/^modals\[.+]$/)) {
                searchParams.delete(key);
            }
        }
        searchParams = searchParams.toString();

        return this.props.dataPath || (searchParams ? `${pathname}?${searchParams}` : pathname);
    }

    resolveComponent(component) {
        if (typeof component === 'string') {
            return new Promise(resolve => {
                requirejs([component], module => {
                    resolve(module);
                });
            });
        }

        if (typeof component === 'function') {
            return component();
        }

        return Promise.resolve(component);
    }

    async load(component, dataPath) {
        const { isInitial, ...data } = this.context.data;

        const [module, newData] = await Promise.all([
            this.resolveComponent(component),
            isInitial ? data : api.get(dataPath),
        ]);

        this.context.setData(newData);
        statusMessages(newData.status);
        this.setState({
            Component: module.default || module,
        });
    }

    render() {
        const { Component } = this.state;
        const { redirect, status, ...props } = this.context.data || {};

        if (redirect) {
            return <Redirect to={prepareUrl(redirect)} />;
        }

        if (!Component || !props) {
            return null;
        }

        return (
            <Component {...props} />
        );
    }
}

RequireComponent.propTypes = {
    location: PropTypes.object.isRequired,
    component: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
        PropTypes.func,
    ]).isRequired,
    dataPath: PropTypes.string,
};

RequireComponent.defaultProps = {
    dataPath: null,
};

RequireComponent.contextType = DataContext;

export default withRouter(RequireComponent);
