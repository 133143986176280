// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { render } from 'react-dom';
import { createElement, createPortal } from 'react';
import { LocaleProvider } from '@plesk/ui-library';
import { Locale } from 'jsw';
import App from './components/App';
import ErrorBoundary from './components/ErrorBoundary';
import require from './require';
import sentry from 'common/sentry';

import './index.less';

export { require };
export { default as Form } from './components/Form';

const isInFrame = () => {
    try {
        if (window.self === window.top) {
            return false;
        }
    } catch (e) {
    }

    return true;
};

let app = null;

export const run = ({ localeSections = {}, ...props }) => {
    const { config = {} } = props;

    if (config.feedback) {
        sentry(config.feedback);
    }

    if (isInFrame()) {
        document.body.classList.add('page-content-only');
    }

    Object.keys(localeSections).forEach(section => {
        Locale.addSection(section, localeSections[section]);
    });

    app = render(
        <App {...props} />,
        document.getElementById('plesk-root')
    );
};

export const addWidget = (Component, { locale, ...props }, container) => {
    let widget = <Component {...props} />;

    if (locale) {
        widget = <LocaleProvider messages={locale}>{widget}</LocaleProvider>;
    }

    widget = (
        <ErrorBoundary>
            {widget}
        </ErrorBoundary>
    );

    if (container) {
        widget = createPortal(widget, container);
    }

    app.addWidget(widget);
};
