// Copyright 1999-2021. Plesk International GmbH. All rights reserved.

import { List, list } from '../list';
import { SmallButton } from '../small-button';
import { bar } from '../bar';
import Locale from '../Locale';
import prepareUrl from '../prepareUrl';
import escapeHtml from '../escapeHtml';

export const Domains = class extends List {
    _initConfiguration(config) {
        super._initConfiguration({
            searchOveral: 'domainName',
            ...config,
        });

        this._disabledSelectHint = this.lmsg('disabledSelectHint');
        this._urls = this._getConfigParam('urls', {});
        this._icons = this._getConfigParam('icons', {});
        this._userId = this._getConfigParam('userId');

        this._operations = [];
        if (this._urls.createDomain) {
            this._operations.push({
                componentType: SmallButton,
                id: 'buttonAddDomain',
                cls: 's-btn sb-new-domain',
                title: this.lmsg('buttonAddDomain'),
                description: this.lmsg('hintAddDomain'),
                href: this._urls.createDomain,
            });
        }
        if (this._urls.createSubdomain) {
            this._operations.push({
                componentType: SmallButton,
                id: 'buttonAddSubDomain',
                cls: 's-btn sb-new-subdomain',
                title: this.lmsg('buttonAddSubdomain'),
                description: this.lmsg('hintAddSubdomain'),
                href: this._urls.createSubdomain,
            });
        }
        if (this._urls.createDomainAliases) {
            this._operations.push({
                componentType: SmallButton,
                id: 'buttonAddDomainAlias',
                cls: 's-btn sb-new-domain-alias',
                title: this.lmsg('buttonAddDomainAlias'),
                description: this.lmsg('hintAddDomainAlias'),
                href: this._urls.createDomainAliases,
            });
        }
        if (this._operations.length) {
            this._operations.push({
                componentType: bar.Separator,
            });
        }
        this._operations.push({
            componentType: list.AdditionalActions,
            title: this.lmsg('buttonChangeStatus'),
            operations: [{
                componentType: SmallButton,
                id: 'buttonSuspendDomains',
                title: this.lmsg('buttonSuspendDomains'),
                description: this.lmsg('hintSuspendDomains'),
                addCls: 'sb-suspend',
                cls: 'sb-btn',
                handler: function () {
                    this.execGroupOperation({
                        url: this._urls.suspend,
                        skipConfirmation: true,
                    });
                }.bind(this),
            }, {
                componentType: SmallButton,
                id: 'buttonDisableDomains',
                title: this.lmsg('buttonDisableDomains'),
                description: this.lmsg('hintDisableDomains'),
                addCls: 'sb-turn-off',
                cls: 'sb-btn',
                handler: function () {
                    this.execGroupOperation({
                        url: this._urls.disable,
                        skipConfirmation: true,
                    });
                }.bind(this),
            }, {
                componentType: SmallButton,
                id: 'buttonActivateDomains',
                title: this.lmsg('buttonActivateDomains'),
                description: this.lmsg('hintActivateDomains'),
                addCls: 'sb-activate',
                cls: 'sb-btn',
                handler: function () {
                    this.execGroupOperation({
                        url: this._urls.activate,
                        skipConfirmation: true,
                    });
                }.bind(this),
            }],
        });
        if (this._urls.remove) {
            this._operations.push({
                componentType: bar.Separator,
            });
            this._operations.push({
                componentType: SmallButton,
                id: 'buttonRemoveSite',
                title: Locale.getSection('components.buttons').lmsg('remove'),
                description: this.lmsg('hintRemove'),
                cls: 's-btn sb-remove-selected',
                handler: function (event) {
                    const domains = this.getSelectedItems();
                    const safeDelete = domains.every(domain => (domain.relatedDomains.length === 0));

                    let confirmText = this.lmsg('removeConfirmation');
                    if (!safeDelete) {
                        let wouldBeDeleted = [];
                        for (const domain of domains) {
                            if (domain.relatedDomains.length === 0) {
                                continue;
                            }
                            wouldBeDeleted = wouldBeDeleted.concat(domain.relatedDomains);
                        }

                        if (wouldBeDeleted.length) {
                            wouldBeDeleted = wouldBeDeleted.filter((value, index, self) => self.indexOf(value) === index);
                            confirmText = this.lmsg('removeConfirmationSubscription', {
                                domains: wouldBeDeleted.join(', '),
                            });
                        }
                    }
                    confirmText = confirmText.replace(/\n/gm, '<br>');

                    const homonymSearch = this._getConfigParam('homonymSearch');
                    this.execGroupOperation({
                        url: this._urls.remove,
                        subtype: 'delete',
                        mouseEvent: event,
                        locale: {
                            confirmOnGroupOperation: confirmText,
                            buttonYes: Locale.getSection('components.buttons').lmsg('remove'),
                            buttonNo: Locale.getSection('components.buttons').lmsg('cancel'),
                        },
                        isAjax: !!homonymSearch,
                        requestUrl: homonymSearch?.url,
                        loadingTitle: homonymSearch?.title,
                    });
                }.bind(this),
            });
        }

        this._columns.push(list.COLUMN_SELECTION);
        this._columns.push({
            header: this.lmsg('domainName'),
            sortable: true,
            dataIndex: 'domainDisplayName',
            renderer: function (item) {
                let message = '';
                if (parseInt(item.domainStatus)) {
                    let messageText = this.lmsg('statusDisabled');
                    let messageIcon = this._icons.disabled;
                    let messageAlt = 'disabled';
                    if (item.turnOffAction === 'suspend') {
                        messageText = this.lmsg('statusSuspended');
                        messageIcon = this._icons.suspended;
                        messageAlt = 'suspended';
                    }
                    message += '<div class="b-indent"><span class="b-indent-icon"><span>' +
                        `<span class="tooltipData">${messageText}</span>` +
                        `<img src="${messageIcon}" alt="${messageAlt}" title="">` +
                        '</span>';
                }

                message += (item.overviewUrl ? `<a href="${prepareUrl(item.overviewUrl)}">${escapeHtml(item.domainDisplayName)}</a>` : escapeHtml(item.domainDisplayName));

                if (parseInt(item.domainStatus)) {
                    message += '</span></div>';
                }

                let description = '';
                if (item.adminDescription) {
                    description += '<div class="hint">' +
                        `${escapeHtml(item.adminDescription.truncate(50, '...'))}` +
                        `<span class="tooltipData">${escapeHtml(item.adminDescription)}</span>` +
                    '</div>';
                }
                if (item.resellerDescription) {
                    const descriptionTitle = (item.hideResellerTitleDescription) ? '' : `${this.lmsg('resellerDescription')}: `;
                    description += '<div class="hint">'
                        + `${descriptionTitle}${escapeHtml(item.resellerDescription.truncate(50, '...'))}` +
                        `<span class="tooltipData">${escapeHtml(item.resellerDescription)}</span></div>`;
                }
                if (item.ownerDescription) {
                    description += `<div class="hint">${this.lmsg('ownerDescription')}: ${escapeHtml(item.ownerDescription.truncate(50, '...'))}` +
                        `<span class="tooltipData">${escapeHtml(item.ownerDescription)}</span></div>`;
                }
                message += description;

                return message;
            }.bind(this),
        });
        if (this._getConfigParam('icpPermitColumn')) {
            this._columns.push(this._getConfigParam('icpPermitColumn'));
        }
        this._columns.push({
            header: this.lmsg('hostingType'),
            sortable: true,
            dataIndex: 'hostingType',
            renderer: function (item) {
                let message = '';

                if (item.hostingType === 'alias') {
                    message = `<img src="${this._icons.alias}"/> ${this.lmsg('aliasFor')} `
                        + `<a href="http://${escapeHtml(item.realDomainName)}" target="_blank">${escapeHtml(item.realDomainDisplayName)}</a>`;
                } else if (item.hostingType === 'vrt_hst') {
                    message = `<img src="${this._icons.website}"/>`
                        + ` ${this.lmsg(item.parentDomainId > 0 ? 'subdomainWebsite' : 'website')}`;
                } else if (item.hostingType === 'none') {
                    message = `<img src="${this._icons.none}"/>`
                        + `<em class="hint">${this.lmsg('noHosting')}</em>`;
                } else if (item.hostingType === 'frm_fwd' || item.hostingType === 'std_fwd') {
                    message = `<img src="${this._icons.forwarding}"/>`
                        + ` ${this.lmsg('forwardingTo')} `
                        + `<a href="${escapeHtml(item.forwardingUrl)}" target="_blank">${escapeHtml(item.forwardingDisplayUrl)}</a>`;
                }

                return message;
            }.bind(this),
        });
        if (this._getConfigParam('showOwnerName')) {
            this._columns.push({
                header: this.lmsg('ownerName'),
                sortable: true,
                dataIndex: 'ownerName',
                renderer: function (item) {
                    let linkHref = null;
                    if (parseInt(item.ownerId) !== this._userId) {
                        if ('client' === item.ownerType) {
                            linkHref = '/admin/customer';
                        } else if ('reseller' === item.ownerType) {
                            linkHref = '/admin/reseller';
                        }
                    }
                    return (linkHref ? `<a href="${prepareUrl(`${linkHref}/overview/id/${item.ownerId}`)}">` : '')
                        + escapeHtml(item.ownerName) +
                        (linkHref ? '</a>' : '') +
                        (item.ownerCompanyName ? `, ${escapeHtml(item.ownerCompanyName)}` : '');
                }.bind(this),
            });
        }
        this._columns.push({
            header: this.lmsg('setupDate'),
            sortable: true,
            dataIndex: 'setupDate',
            renderer(item) {
                return escapeHtml(item.setupDateString);
            },
        });
        if (this._getConfigParam('showExpirationDate')) {
            this._columns.push({
                header: this.lmsg('expirationDate'),
                sortable: false,
                dataIndex: 'expirationDate',
                renderer(item) {
                    if (item.expirationDateString) {
                        return escapeHtml(item.expirationDateString);
                    }
                    return '&mdash;';
                },
            });
        }
        this._columns.push({
            header: this.lmsg('diskUsage'),
            sortable: true,
            dataIndex: 'realSize',
            renderer(item) {
                return escapeHtml(item.diskUsage);
            },
        });
        this._columns.push({
            header: this.lmsg('traffic'),
            sortable: false,
            dataIndex: 'traffic',
        });
        this._columns.push({
            header: '',
            sortable: false,
            renderer: function (item) {
                if (item.hostingType === 'none') {
                    return '';
                }

                return `<a href="${escapeHtml(item.siteUrl)}" class="s-btn sb-publish" target="_blank"><span>${this.lmsg('openSite')}</span></a>`;
            }.bind(this),
        });
        this._columns.push({
            header: '',
            sortable: false,
            renderer: function (item) {
                if (!item.previewSupported) {
                    return '';
                }

                return `<a href="/admin/domain/preview?domainId=${item.domainId}" class="s-btn sb-view-site" target="_blank"><span>${this.lmsg('previewSite')}</span></a>`;
            }.bind(this),
        });
        this._columns.push({
            header: '',
            sortable: false,
            renderer: function (item) {
                if (!item.manageUrl || parseInt(item.ownerId) === this._userId) {
                    return '';
                }

                const href = `/admin${item.ownerType === 'reseller' ? '/reseller' : '/customer'}/login/id/${item.ownerId}?pageUrl=${encodeURIComponent(item.manageUrl)}`;

                return `<a href="${href}" class="s-btn sb-login"><span>${
                    this.lmsg(item.ownerType === 'reseller' ? 'manageInResellerPanel' : 'manageInCustomerPanel')
                }</span></a>`;
            }.bind(this),
        });
    }
};
